body, html {
    font-family: 'Lato', sans-serif;
    font-weight: 600; 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    padding: 20px;
}