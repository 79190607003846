.hover-underline {
    position: relative;
    color: black;
    text-decoration: none;
}

.hover-underline::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    width: 0;
    height: 3px;
    background: linear-gradient(to right, rgba(255, 0, 255, 0.7), rgba(0, 255, 255, 0.7)); 
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.hover-underline:hover::before {
    width: 100%;
}

.navbar-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 50;
}

.main-content {
    padding-top: 60px;
}