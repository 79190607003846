.animate-blink {
    animation: blink 1s step-start infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.90);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.animate-fadeInScale {
    animation: fadeInScale 1s ease forwards;
}

.animate-blink {
    animation: blink 1s steps(1) infinite;
}
